import { useEffect, useState } from 'react';
import { IUser } from '../../utils/auth-context';

export const useOrganisationHasAccess: any = (user: IUser) => {
    // banks that are allowed into the portal (dev: 986399445)
    const paidOrgNumber = ['984851006', '937899319', '832554332', '937901003', '937895321', '920058817','937894538','986399445'];

    const [organisationHasAccess, setOrganisationHasAccess] = useState<boolean>(false);

    useEffect(() => {
        // debug for easier support
        console.log('Customer = ');
        console.log(organisationHasAccess ? 'true' : 'false');
    }, [organisationHasAccess]);

    useEffect(() => {
        if (!user) return;
        console.log(user.organisation.organisationNumber);
        if (paidOrgNumber.includes(user.organisation.organisationNumber)) setOrganisationHasAccess(true);
    }, [user]);

    return organisationHasAccess;
};
