import { AppThunkAction } from ".";
import { Action, Reducer } from 'redux';
import _ from 'lodash';
import Axios from 'axios';
import { Guid } from "guid-typescript";

export interface ScoreMapping {
    bankRiskScore: string,
    eifRiskScore: string,
    index: number
}

export interface Organisation {
    id: string,
    displayName: string,
    organisationNumber: string,
    address: string,
    postCode: string,
    city: string
    phoneNumber: string,
    tenantId: string,
    riskScoreMappings: ScoreMapping[],
}

export interface OrganisationDetailsState {
    organisation: Organisation | undefined,
    updates: Organisation | undefined,
    isLoading: boolean,
    error: string,
    markedForDeletion: boolean,
}

export interface OrganisationRegistryEntry {    
  name: string,
  orgId: string,
  nace: string,
  street: string,
  zip: string,
  city: string,
  country: string
}

// Actions
 interface RequestOrganisationAction { type: 'REQUEST_ORGANISATION', organisationId: string }
 interface RequestOrganisationFailedAction { type: 'REQUEST_ORGANISATION_FAILED', error: string }
 interface RequestOrganisationSucceededAction { type: 'REQUEST_ORGANISATION_SUCCEEDED', organisation: Organisation }
 interface SaveOrganisationAction { type: 'SAVE_ORGANISATION' }
 interface SaveOrganisationFailedAction { type: 'SAVE_ORGANISATION_FAILED', error: string }
 interface SaveOrganisationSucceededAction { type: 'SAVE_ORGANISATION_SUCCEEDED', organisationId: string }
 interface UpdateOrganisationAction { type: 'UPDATE_ORGANISATION', organisation: Organisation }
 interface RestoreOrganisationAction { type: 'RESTORE_ORGANISATION' }
 interface InitializeOrganisationAction { type: 'INITIALIZE_ORGANISATION' }
 interface MarkForDeletionAction { type: 'MARK_FOR_DELETION' }
 interface LoadOrganisationRegistryDataAction { type: 'LOAD_ORGANISATION_REGISTRY_DATA', organisationNumber: string }
 interface LoadOrganisationRegistryDataSucceededAction { type: 'LOAD_ORGANISATION_REGISTRY_DATA_SUCCEEDED',  organisation: OrganisationRegistryEntry}
 interface LoadOrganisationRegistryDataFailedAction { type: 'LOAD_ORGANISATION_REGISTRY_DATA_FAILED', error: string }
 

 type KnownAction = RequestOrganisationAction | RequestOrganisationFailedAction | RequestOrganisationSucceededAction
                    | SaveOrganisationAction | SaveOrganisationFailedAction | SaveOrganisationSucceededAction
                    | UpdateOrganisationAction | RestoreOrganisationAction | InitializeOrganisationAction
                    | MarkForDeletionAction | LoadOrganisationRegistryDataAction | LoadOrganisationRegistryDataSucceededAction | LoadOrganisationRegistryDataFailedAction;

 // Action creators
export const actionCreators = {
    requestOrganisation: (organisationId: string): AppThunkAction<KnownAction> => (dispatch) => {
        Axios.get(`/api/Organisation/${organisationId}`)
            .then(
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        dispatch({ type: 'REQUEST_ORGANISATION_SUCCEEDED', organisation: response.data });
                    } else {
                        dispatch({ type: 'REQUEST_ORGANISATION_FAILED', error: response.statusText });
                    }
                },
                error => {
                    dispatch({ type: 'REQUEST_ORGANISATION_FAILED', error: error });
                }
            );
        dispatch({ type: 'REQUEST_ORGANISATION', organisationId });
    },

    saveOrganisation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const store = getState().organisationDetails;
        if (store === undefined) {
            return;
        }
        if (store.markedForDeletion && store.organisation) {
            Axios.delete(`/api/Organisation/${store.organisation.id}`)
                .then(
                    response => {
                        if (response.status >= 200 && response.status < 300) {
                            dispatch({ type: 'SAVE_ORGANISATION_SUCCEEDED', organisationId: '' });
                        } else {
                            dispatch({ type: 'SAVE_ORGANISATION_FAILED', error: response.statusText });
                        }
                    },
                    error => {
                        dispatch({ type: 'SAVE_ORGANISATION_FAILED', error: error });
                    }
                )
            dispatch({ type: 'SAVE_ORGANISATION' });
        } else {
            const organisation = getState().organisationDetails!.updates;
            if (organisation === undefined) {
                return;
            }
            Axios.put(`/api/Organisation`, {
                ...organisation,
                tenantId: btoa(organisation.tenantId)
            })
                .then(
                    response => {
                        if (response.status >= 200 && response.status < 300) {
                            dispatch({ type: 'SAVE_ORGANISATION_SUCCEEDED', organisationId: response.data });
                        } else {
                            dispatch({ type: 'SAVE_ORGANISATION_FAILED', error: response.statusText });
                        }
                    },
                    error => {
                        dispatch({ type: 'SAVE_ORGANISATION_FAILED', error: error });
                    }
                )
            dispatch({ type: 'SAVE_ORGANISATION' });
        }
    },

    loadOrganisationRegistryData: (organisationNumber: string): AppThunkAction<KnownAction> => (dispatch) => {
        Axios.get(`/api/OrganisationRegistry/${organisationNumber}`)
            .then(
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        dispatch({ type: 'LOAD_ORGANISATION_REGISTRY_DATA_SUCCEEDED', organisation: response.data });
                    } else {
                        dispatch({ type: 'LOAD_ORGANISATION_REGISTRY_DATA_FAILED', error: response.statusText });
                    }
                },
                error => {
                    dispatch({ type: 'LOAD_ORGANISATION_REGISTRY_DATA_FAILED', error: error });
                }
            )
            dispatch({ type: 'LOAD_ORGANISATION_REGISTRY_DATA', organisationNumber: organisationNumber });
    },

    updateOrganisation: (organisation: Organisation): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'UPDATE_ORGANISATION', organisation});
    }, 

    restoreOrganisation: (): any => ({ type: 'RESTORE_ORGANISATION' }),

    initilizeOrganisation: (): any => ({ type: 'INITIALIZE_ORGANISATION' }),

    markForDeletion: (): any => ({ type: 'MARK_FOR_DELETION' }),
}

const initialState: OrganisationDetailsState = {
    error: '',
    isLoading: false,
    organisation: undefined,
    updates: undefined,
    markedForDeletion: false,
};

const initialOrganisation: Organisation = {
    address: '',
    city: '',
    displayName: '',
    id: Guid.createEmpty().toString(),
    organisationNumber: '',
    phoneNumber: '',
    postCode: '',
    riskScoreMappings: [],
    tenantId: '',
}

export const reducer : Reducer<OrganisationDetailsState> = (state: OrganisationDetailsState | undefined, incomingAction: Action): OrganisationDetailsState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch(action.type) {
        case 'REQUEST_ORGANISATION': {
            return {
                ...state,
                isLoading: true,
                error: ''
            }
        }
        case 'REQUEST_ORGANISATION_FAILED': {
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        }
        case 'REQUEST_ORGANISATION_SUCCEEDED': {
            return {
                ...state,
                error: '',
                organisation: {
                    ...initialOrganisation,
                    ...action.organisation
                },
                updates: undefined,
                isLoading: false
            };
        }
        case 'RESTORE_ORGANISATION': {
            return {
                ...state,
                updates: undefined,
                markedForDeletion: false,
            }
        }
        case 'SAVE_ORGANISATION': {
            return {
                ...state,
                isLoading: true,
                error: '',
            };
        }
        case 'SAVE_ORGANISATION_FAILED': {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case 'SAVE_ORGANISATION_SUCCEEDED': {
            const currentOrg = (state.markedForDeletion ? undefined
                : state.updates ? {
                    ...state.updates,
                    id: action.organisationId
                } : {
                    ...state.organisation
                }) as Organisation | undefined;
            return {
                ...state,
                isLoading: false,
                organisation: currentOrg,
                updates: undefined,
                markedForDeletion: false,
                error: ''
            };
        }
        case 'UPDATE_ORGANISATION': {
            return {
                ...state,
                updates: _.isEqual(state.organisation, action.organisation) ? undefined : action.organisation
            };
        }
        case 'INITIALIZE_ORGANISATION': {
            return {
                organisation: {
                    ...initialOrganisation
                },
                error: '',
                isLoading: false,
                updates: undefined,
                markedForDeletion: false
            };
        }
        case 'MARK_FOR_DELETION': {
            return {
                ...state,
                markedForDeletion: true
            }
        }
        case 'LOAD_ORGANISATION_REGISTRY_DATA': {
            return {
                ...state,
                isLoading: true
            }
        }
        case 'LOAD_ORGANISATION_REGISTRY_DATA_SUCCEEDED': {
            return {
                ...state,
                updates: {
                    ...state.organisation,
                    ...state.updates!,
                    address: action.organisation.street,
                    displayName: action.organisation.name,
                    postCode: action.organisation.zip,
                    city: action.organisation.city 
                },
                isLoading: false
            }
        }
        case 'LOAD_ORGANISATION_REGISTRY_DATA_FAILED': {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        default: return state;
    }
}