import React, { useEffect, useState, useRef, useContext, VFC } from 'react';
import Axios, { AxiosResponse } from 'axios';
import { timer } from 'rxjs';
import { Formik, Form, Field, FormikHelpers, FormikProps, FormikTouched, useFormikContext, FormikValues } from 'formik';

import DynamicControls from '../dynamic/index';

import { cloneDeep, mapValues, keyBy } from 'lodash';

import './input-schema.scss';
import { AxiosRequestConfig } from 'axios';
import { UserContext, ILoggedOnUser, UserConsumer } from '../../utils/auth-context';
import { Callbacks, resetOrgNumber } from './callbacks';
import { ContactInformation, GetContactInformation } from './contact-information';

import SchemaDefinition from '../../assets/schema/schema-backing-v3.json';

import { ApplicationContextWrapper, ApplicationContext, IDispatchAction, IApplicationContext } from './application-context';
import cn from 'classnames';
import 'moment/locale/nb';
import { MessageContext, ShowMessages } from '../../utils/message-context/message-context';
import { createPayLoad } from './payload';
import { saveApplicationValuesIntoSession, IApplicationValues, reAuthenticate, getValuesSavedByReauthentication, updateApplicationValues, loadApplicationValuesFromSession } from './reauthenticate';
import { PageLoader, FormPage, Button, Theme, InfoBox, ButtonSize, IconPosition, InfoIcon } from '@in/component-library';
import { customValidate, customValidateFiles, customValidateCheckbox, generateYupSchema } from './customValidation';
import { ISummaryState } from '../dynamic/reflect-summary-cl/reflect-summary-cl';
import Constraint from '../constraint/constraint';

import { NavLink } from 'react-router-dom';
import { useResponsiveView } from '../hooks/useResponsiveView';
import { FileItemExtended } from '../dynamic/input-dropzone-cl/input-dropzone-cl';
import { devTest } from '../../utils/devtest';

// Presisering for validering:
// Ifølge Skatteetatens kommunikasjonsavdeling, kan et navn som registreres i folkeregisteret inneholde maks 150 tegn totalt, fordelt på maks 50 tegn på for-, mellom- og etternavn.
// Allerede ved 25 tegn, hvis du skulle være så heldig å ha et navn med mer enn dette, vil navnet ditt forkortes i brev fra det offentlige.

export enum BUTTON_ACTION {
    Next = 2,
    Previous = 1,
    Save = 0,
}

enum DynamicField {
    First = 0,
    Forth = 3,
    Second = 1,
    Third = 2,
}

const EMPTY_STRING = '';

export interface ISchema {
    metadata?: IMetaData;
    pages: IPage[];
}

interface IMetaData {
    groupId: string;
    transmitted: number;
}

export interface IPage {
    buttons: IButton[];
    changeable?: boolean;
    id: number;
    layout?: string;
    summaryId: number;
    title: string;
    validateOnBlur: boolean;
    values: (IValue | IGroup | IInformation)[];
}

interface IButton {
    api?: string;
    color?: string;
    path?: string;
    position?: string;
    store: string;
    submittype: string;
    text: string;
    type: string;
}

export interface IGroup {
    buttontext?: string;
    changeable?: boolean;
    conditional?: any;
    id: string;
    label: string;
    rows?: number;
    template: string;
    values: IValue[];
}

interface ICallback {
    functionName: string;
    parameters?: string[];
}

export interface IValue {
    api?: IApi;
    callback?: ICallback;
    changeable?: boolean;
    conditional?: any;
    customvalidations?: IValidation[];
    dirty?: boolean;
    disabled?: boolean;
    focus?: boolean;
    format?: IFormat;
    help?: string;
    hidden?: boolean;
    id: string;
    label?: string;
    labelSecondary?: string;
    maxFiles?: number;
    maxLength?: number;
    maxwidth?: string;
    maxwidthem?: string;
    minFiles?: number;
    minwidthem?: string;
    options?: IOption[];
    overflow?: boolean;
    payloadkey?: string;
    placeholder?: string;
    prefix?: string;
    readonly?: boolean;
    removefrompayload?: boolean;
    role?: string;
    rules?: IRule[];
    standaloneid?: boolean;
    stash?: string;
    suffix?: string;
    tab?: boolean;
    texts?: IText;
    type: string;
    validation?: string;
    validations?: IValidation[];
    validationType?: string;
    value: string | boolean;
    valueprefix?: string;
    version?: string;
    width?: string;
}

export interface IFormat {
    decimals?: number;
    max?: number;
    min?: number;
    type: string;
}

interface IText {
    dragin?: string;
    draginActive?: string;
    draginInfo?: string;
    faulty?: string;
    info?: string;
    processing?: string;
    tooltip?: string;
    uploading?: string;
}

export interface IInformation {
    header: string;
    header2?: string;
    help?: string;
    id: string;
    space?: number;
    type: string;
    value?: number;
}

export interface IOption {
    key: string;
    text: string;
    value: string;
}
export interface IValidation {
    dependencies?: IDependency[];
    otherfield?: (string | number | boolean)[];
    params: (string | number | boolean)[];
    type: string;
}

interface IDependency {
    addmonthsfromid?: string;
    id: string;
    operator: string;
}

interface IApi {
    mapping: IMap[];
    url: string;
}

interface IMap {
    destination: string;
    source: string;
}

export interface IStoredSchema {
    fields: IStoredField[];
    metadata: IMetaData;
}

export interface IStoredField {
    displayValue?: string;
    format: string;
    groupId?: string;
    groupRow?: number;
    id: string;
    label: string;
    numericValue?: number | undefined;
    pageId: number;
    suffix?: string;
    type: string;
    value: string;
}

export interface IFileValidationArgument {
    errorUploadingFiles: boolean;
    maxFiles: number;
    minFiles: number;
    name: string;
    totalFiles: number;
    value: number;
    files: FileItemExtended[];
}

export interface IRule {
    actions: IRuleAction[];
    onchangelessthanorequalto?: number;
    onchangeto?: string;
}

export interface IRuleAction {
    exceptwhen?: string;
    id: string;
    property: string;
    value: any;
}

enum MessageType {
    Error = 'error',
    Info = 'info',
    Success = 'success',
    Warning = 'warning',
}

export enum DisplayMode {
    Information = 1,
    Value = 0,
}

interface InputSchemaProps {
    inputSchema: ISchema
}

export function isValue(object: Record<string, any>): object is IValue {
    return !('template' in object || 'header' in object);
}

export function isGroup(objectl: Record<string, any>): objectl is IGroup {
    return 'template' in objectl;
}

export interface IOverlay {
    [id: string]: IValue;
}

// Defaults
const INITIAL_SCHEMA: ISchema = cloneDeep(SchemaDefinition);
const INITIAL_PAGEID = 0;

const ResponsiveWidthThresholdMedium = 500;

// Hold the saved values after loading it. We need it as default data throughout all the pages, and can not rely on reading it successively per page in case it expires prematurely during the case handling period.
let savedSchemaValues: IApplicationValues | undefined = undefined;

export function resetSchemaValues():void {
    savedSchemaValues = undefined;
    resetOrgNumber();
    const appValues = loadApplicationValuesFromSession();
    if (appValues !== undefined) {
        localStorage.removeItem('ApplicationValues');
    }
}

const InputSchema: VFC<InputSchemaProps> = (props) => {
    const { width } = useResponsiveView();

    // Application context
    const applicationContextValue: IApplicationContext = useContext(ApplicationContext);
    const messageContext = useContext(MessageContext);

    // Store the schema
    const [schema, setSchema] = useState<ISchema>(props.inputSchema ? props.inputSchema : INITIAL_SCHEMA);

    // Show one transaction as default
    const [otherTransactionsCounter, setOtherTransactionsCounter] = useState<number>(1);

    useEffect(() => {
        if (savedSchemaValues === undefined) {
            // Only read PageId once
            savedSchemaValues = getValuesSavedByReauthentication();
            if (savedSchemaValues !== undefined) {
                if (savedSchemaValues !== undefined && savedSchemaValues.PageId !== undefined) {
                    setPageId(savedSchemaValues.PageId);
                    localStorage.removeItem('ApplicationValues');
                }
                if (savedSchemaValues.ApplicationContext !== undefined && savedSchemaValues.ApplicationContext.applicationId !== undefined) {
                    const action: IDispatchAction = {
                        Command: 'applicationId',
                        FieldName: '',
                        Value: savedSchemaValues.ApplicationContext.applicationId,
                        Values: {},
                    };
                    applicationContextValue.dispatch(action);
                }
            }
        }
    }, [applicationContextValue]);

    // Store the id of what page we are on
    const [pageId, setPageId] = useState<number>(INITIAL_PAGEID);

    const [contactInformation, setContactInformation] = useState({});

    // Last changed values in memory
    const [memoryValues, setMemoryValues] = useState({});

    const [wrongOrgNumber, setWrongOrgNumber] = useState<boolean>(false);

    const [isUploadingFiles, setIsUploadingFiles] = useState<boolean>(false);

    // Set to true if editing from summarypage
    interface ISummaryPageActive {
        active: boolean;
        summaryPageId: number;
    }

    const [summaryPageActive, setSummaryPageActive] = useState<ISummaryPageActive>({ active: false, summaryPageId: 0 });

    // manage the collapsed summary from here. That way we maintain its state after paging
    const defaultSummaryState: ISummaryState[] = [{ expanded: true }, { expanded: false }, { expanded: false }, { expanded: false }, { expanded: false }, { expanded: false }, { expanded: false }, { expanded: false }, { expanded: false }];
    const [collapsed, setCollapsed] = useState<Array<ISummaryState>>(defaultSummaryState);

    function resetCollapsed() {
        setCollapsed(defaultSummaryState);
    }

    // Only show validation errors after an attempt to page was done.
    const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);

    const [disabledAndNulledFields, setDisabledAndNulledFields] = useState<Array<string>>([]);

    // autosave every 5 seconds
    timer(2500).subscribe(() => {
        const action: IDispatchAction = {
            Command: 'autoSavedValues',
            FieldName: '',
            Value: '',
            Values: memoryValues,
        };
        applicationContextValue.dispatch(action);
    });

    // HoC into FormikContext
    const cooldown = 2500;
    let nextSaveOpportunity = 0;
    const ContextTap = () => {
        const { values, isValid } = useFormikContext();
        useEffect(() => {
            if (nextSaveOpportunity < Date.now()) {
                if (isValid) {
                    setMemoryValues(values as any);
                    nextSaveOpportunity = Date.now() + cooldown;
                    // console.log('Saving valida data into memory');
                } else {
                    // console.log('Not saving invalid data');
                }
            }
        }, [values, isValid]);
        return null;
    };

    const customfocus = useRef<HTMLInputElement>(null);

    const userContext: ILoggedOnUser = useContext(UserContext);

    useEffect(() => {
        const _contactInformation: ContactInformation | undefined = GetContactInformation(schema, userContext);
        if (_contactInformation !== undefined) {
            setContactInformation(_contactInformation);
        }

        // Emergency save
        return () => {
            if (pageId > 0) {
                const payload = createPayLoad(schema, applicationContextValue.autoSavedValues, userContext);
                save(payload, applicationContextValue.autoSavedValues);
                messageContext.addMessage({ message: 'Søknaden er lagret 2.', type: 'info', view: 'approot', ttl: 2000 });
            } else {
                // console.log('warning: no recovery save on page 1');
            }
        };
    }, []);

    // The schema has been paged
    useEffect(() => {
        updateApplicationValues(undefined, undefined, pageId);
        setShowValidationErrors(false);
        window.scrollTo(0, 0);
        if (customfocus !== null && customfocus !== undefined && customfocus.current !== null && customfocus.current !== undefined) {
            if (customfocus.current.focus !== undefined) {
                customfocus.current.focus();
            } else {
                // Probably not set focus on dates
                // (customfocus.current as any).setFocus();
            }
        }
    }, [pageId]);

    const [blocked, setPageBlocked] = useState<boolean>(false);

    function createInitialValues(_schema: ISchema) {
        // Initial values from session after a reauthenticate
        if (savedSchemaValues !== undefined && Object.keys(savedSchemaValues.SchemaValues).length > 0) {
            // console.log('----------- Updating default values with session value ---------');
            // console.log(savedSchemaValues.SchemaValues);
            return savedSchemaValues.SchemaValues;
        }

        // console.log('createInitialValues triggered with:');
        // console.log(JSON.stringify(_schema));
        const ival: any = {};
        _schema.pages.forEach((page) => {
            page.values.forEach((value) => {
                if (isValue(value)) {
                    if (ival[(value as IValue).id] === undefined) {
                        if (value.type === 'checkbox') {
                            ival[(value as IValue).id] = []; // New checkbox format (v2)
                        } else {
                            ival[(value as IValue).id] = (value as IValue).value;
                        }
                    }
                } else if (isGroup(value)) {
                    if (value.rows !== undefined) {
                        for (let index = 0; index < value.rows; index++) {
                            value.values.forEach((_value) => {
                                ival[(value as IGroup).id + '_' + index + '_' + _value.id] = (_value as IValue).value;
                            });
                        }
                    } else {
                        value.values.forEach((_value) => {
                            if (_value.type === 'group-checkbox') {
                                ival[value.id + '_' + _value.id] = []; // New checkbox format (v2)
                            } else if (_value.standaloneid !== undefined && _value.standaloneid === true) {
                                // console.log('Creating initial value for standalone id: ' + (_value as IValue).value);
                                ival[_value.id] = (_value as IValue).value;
                            } else {
                                ival[value.id + '_' + _value.id] = (_value as IValue).value;
                            }

                            // ival[(value as IGroup).id + '_' + _value.id] = (_value as IValue).value;
                        });
                    }
                    // todo: add values from trees recursively
                }
            });
        });

        ival['action'] = '';
        // console.log('initial values --------------------------------------------------------');
        // console.log(ival);
        return ival;
    }

    function updateSchema() {
        setSchema({ ...schema });
    }

    function createFieldsCl(values: (IGroup | IValue | IInformation)[], layout: string | undefined, formikProps: FormikProps<any>) {
        const fields: JSX.Element[] = [];
        values.forEach((value) => {
            if (isValue(value)) {
                const _value = value as IValue;
                if (_value.version !== undefined && _value.version === '-cl') {
                    fields.push(createFieldCl(formikProps, EMPTY_STRING, _value, layout, undefined));
                }
            } else if (isGroup(value)) {
                const group = value as IGroup;
                if (group.template === 'table') {
                    fields.push(<div key={group.id}>{createTableRowsCl(formikProps, group, group.values)}</div>);
                } else if (group.template === 'checkbox_list') {
                    fields.push(
                        <div className="inputGroup vertical" key={group.id}>
                            <label className="groupLabel">{group.label}</label>
                            {group.values.map((value) => createCheckBoxCl(formikProps, group.id, value))}
                        </div>
                    );
                } else if (group.template === 'cl_row_1_half') {
                    fields.push(
                        <div key={group.id} className="layoutGridMaxElements">
                            {createFieldCl(formikProps, group.id, group.values[DynamicField.First], layout, group.template)}
                        </div>
                    );
                } else if (group.template === 'cl_row_2') {
                    let showTemplate = false;
                    if (group.conditional !== undefined) {
                        if (formikProps.values[group.conditional.showif.key] === group.conditional.showif.value) {
                            showTemplate = true;
                        }
                    } else {
                        showTemplate = true;
                    }
                    if (showTemplate === true) {
                        fields.push(
                            <div key={group.id} className="layoutGridMaxElements layoutGridMax2x">
                                {createFieldCl(formikProps, group.id, group.values[DynamicField.First], layout, group.template)}
                                {createFieldCl(formikProps, group.id, group.values[DynamicField.Second], layout, group.template)}
                            </div>
                        );
                    }
                } else if (group.template === 'cl_row_3') {
                    let showTemplate = false;
                    if (group.conditional !== undefined) {
                        if (formikProps.values[group.conditional.showif.key] === group.conditional.showif.value) {
                            showTemplate = true;
                        }
                    } else {
                        showTemplate = true;
                    }
                    if (showTemplate === true) {
                        fields.push(
                            <div key={group.id} className="layoutGridMaxElements layoutGrid3x">
                                {createFieldCl(formikProps, group.id, group.values[DynamicField.First], layout, group.template)}
                                {createFieldCl(formikProps, group.id, group.values[DynamicField.Second], layout, group.template)}
                                {createFieldCl(formikProps, group.id, group.values[DynamicField.Third], layout, group.template)}
                            </div>
                        );
                    }
                } else if (group.template === 'cl_row_x') {
                    // none or more fields, configured on the field itself. 2 or more

                    // Check if we have any visible fields, and if so how many
                    let visibleFields = 0;
                    group.values.forEach((field) => {
                        if (field.conditional !== undefined) {
                            if (formikProps.values[field.conditional.showif.key] === field.conditional.showif.value) {
                                visibleFields++;
                                field.removefrompayload = false;
                            } else {
                                field.removefrompayload = true;
                            }
                        } else {
                            visibleFields++;
                            field.removefrompayload = false;
                        }
                    });

                    if (visibleFields > 0) {
                        const cells: JSX.Element[] = [];
                        const hiddenCells: JSX.Element[] = [];
                        group.values.forEach((field) => {
                            if (field.conditional !== undefined) {
                                if (formikProps.values[field.conditional.showif.key] === field.conditional.showif.value) {
                                    cells.push(<div key={group.id + '_' + field.id}>{createFieldCl(formikProps, group.id, field, layout, group.template)}</div>);
                                } else {
                                    hiddenCells.push(
                                        <div key={group.id + '_' + field.id} className="hiddenField">
                                            {createFieldCl(formikProps, group.id, field, layout, group.template)}
                                        </div>
                                    );
                                }
                            } else {
                                cells.push(<div key={group.id + '_' + field.id}>{createFieldCl(formikProps, group.id, field, layout, group.template)}</div>);
                            }
                        });
                        fields.push(
                            <div className={cn('layoutGridMaxElements', { layoutGridMax2x: cells.length === 2 }, { layoutGridMax3x: cells.length === 3 })} key={group.id + 'top'}>
                                {cells}
                                {hiddenCells}
                            </div>
                        );
                    }
                } else if (group.template === 'cl_row_generated') {
                    fields.push(
                        <div key={group.id}>
                            <div key={group.id} className="layoutGridMaxElements layoutGridMax2x">
                                {createFieldCl(formikProps, group.id, group.values[DynamicField.First], layout, group.template, false, DisplayMode.Value)}
                                {createFieldCl(formikProps, group.id, group.values[DynamicField.Second], layout, group.template, false, DisplayMode.Value)}
                            </div>
                            <div key={group.id + '2'} className="layoutGridMaxElements">
                                {createFieldCl(formikProps, group.id, group.values[DynamicField.First], layout, group.template, false, DisplayMode.Information)}
                                {createFieldCl(formikProps, group.id, group.values[DynamicField.Second], layout, group.template, false, DisplayMode.Information)}
                            </div>
                        </div>
                    );
                }
            } else {
                if (value.type === 'information') {
                    if (value.value === 1) {
                        fields.push(
                            <div key={value.id}>
                                <div className="informationHelpIcon">
                                    {(value?.help ?? ''.length > 0) && (
                                        <InfoIcon ariaLabel="infoIcon" parentPropName="div">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: value?.help ?? '',
                                                }}
                                            ></div>
                                        </InfoIcon>
                                    )}
                                    <h2 className="removeTopBorder">{value.header}</h2>
                                </div>
                            </div>
                        );
                    } else if (value.value === 2) {
                        fields.push(
                            <div key={value.id} className="freeText">
                                <p>{value.header}</p>
                                <p>{value.header2}</p>
                            </div>
                        );
                    } else {
                        fields.push(
                            <div key={value.id} className="fieldHeader">
                                {value.header}
                            </div>
                        );
                    }
                } else if (value.type === 'space') {
                    fields.push(<div key={value.id} style={{ width: '100%', height: value.space }}></div>);
                }
            }
        });
        return <div key="fields">{fields}</div>;
    }

    function createFieldCl(formikProps: FormikProps<any>, prefix: string, value: IValue, layout: string | undefined, template?: string, hidden?: boolean, displayMode?: DisplayMode) {
        if (value.type === undefined) {
            return <div>Unknown value type</div>;
        }
        if (prefix + '' !== '') {
            prefix += '_';
        }
        if (value.standaloneid !== undefined && value.standaloneid === true) {
            prefix = '';
        }

        // Get callback from schemadefinition. This callback is called in the onBlur event handler.
        let callback: any = undefined;
        if (value.callback !== undefined) {
            const cb = Callbacks.find((c) => c.name === value!.callback!.functionName);
            if (cb !== undefined) {
                const args: string[] = value!.callback!.parameters as string[];
                const func = cb.func;
                if (args === undefined) {
                    callback = (e: any, setFieldValue: any) => func(e, value, setFieldValue, setWrongOrgNumber, formikProps.validateField);
                } else {
                    callback = (e: any, setFieldValue: any) => func(e, value, setFieldValue, setWrongOrgNumber, formikProps.validateField, ...args);
                }
            }
        }
        return (
            <Field
                callback={callback}
                collapsed={collapsed}
                component={DynamicControls[value.type + value.version]}
                contactinformation={contactInformation}
                customfocus={customfocus}
                dirty={value.dirty}
                disabled={value.disabled}
                disabledAndNulledFields={disabledAndNulledFields}
                displaymode={displayMode}
                focus={value.focus}
                format={value.format}
                hidden={hidden}
                id={prefix + value.id} // needed for yup
                inputClassName="input"
                key={prefix + value.id}
                label={value.label}
                labelClassName="form-label"
                layout={layout}
                mapvalue={value.value}
                maxfiles={value.maxFiles}
                maxlength={value.maxLength}
                maxwidth={value.maxwidth}
                maxwidthem={value.maxwidthem}
                minfiles={value.minFiles}
                minwidthem={value.minwidthem}
                name={prefix + value.id} // convension for forms
                options={value.options}
                overflow={value.overflow}
                pageid={pageId}
                placeholder={value.placeholder}
                readonly={value.readonly}
                rules={value.rules}
                schemavalue={value} // todo: use this for the others too
                setcollapsed={setCollapsed}
                setpageblocked={setPageBlocked}
                setpageid={setPageId}
                setsummarypageactive={setSummaryPageActive}
                suffix={value.suffix}
                tab={value.tab}
                template={template}
                texts={value.texts}
                type={value.type}
                updateschema={updateSchema}
                uploadingfiles={setIsUploadingFiles}
                validate={getValidation(value, formikProps, prefix)}
                width={value.width}
            />
        );
    }

    function getValidation(value: IValue, formikProps: FormikProps<any>, prefix: string) {
        return interceptValidationWhenInvisible(value, formikProps)
            ? undefined
            : value.type === 'checkbox'
            ? (checkboxValue) => customValidateCheckbox(checkboxValue, value, formikProps, setDisabledAndNulledFields)
            : value.type === 'files'
            ? customValidateFiles
            : customValidate(formikProps, prefix, value, setWrongOrgNumber, wrongOrgNumber);
    }

    function interceptValidationWhenInvisible(field: IValue, formikProps: FormikProps<any>): boolean {
        if (field.conditional !== undefined) {
            if (formikProps.values[field.conditional.showif.key] !== field.conditional.showif.value) {
                return true;
            }
        }
        return false;
    }

    function createCheckBoxCl(formikProps: FormikProps<any>, groupId: string, value: IValue) {
        let _checkboxClassName = 'input-group-checkbox-cl';
        _checkboxClassName += value.tab === true ? ' tabbed' : '';
        return (
            <Field
                checkboxClassName={_checkboxClassName}
                component={DynamicControls[value.type + value.version]}
                id={groupId + '_' + value.id}
                inputClassName="input"
                key={groupId + '_' + value.id}
                label={value.label}
                labelClassName="form-checkbox-label"
                mapvalue={value.value}
                maxwidth={value.maxwidth}
                name={groupId + '_' + value.id}
                showvalidationerrors={showValidationErrors}
                overflow={value.overflow}
                suffix={value.suffix}
                tab={value.tab}
                texts={value.texts}
                type={value.type}
                validate={customValidate(formikProps, groupId + '_' + value.id, value, setWrongOrgNumber, wrongOrgNumber)}
            />
        );
    }

    function createTableRowsCl(formikProps: FormikProps<any>, group: IGroup, values: IValue[]) {
        const rows: JSX.Element[] = [];
        if (group.rows !== undefined) {
            for (let index = 0; index < group.rows && index < otherTransactionsCounter; index++) {
                rows.push(
                    <div key={group.id + '_' + index} className="layoutGridMaxElements layoutGrid3x">
                        <div>{createFieldCl(formikProps, group.id + '_' + index, values[DynamicField.First], undefined, undefined)}</div>
                        <div>{createFieldCl(formikProps, group.id + '_' + index, values[DynamicField.Second], undefined, undefined)}</div>
                        <div>{createFieldCl(formikProps, group.id + '_' + index, values[DynamicField.Third], undefined, undefined)}</div>
                    </div>
                );
            }
            rows.push(
                <div key={group.id + '_last'}>
                    {rowsRemaining(group.rows) && canExpandRows(otherTransactionsCounter, group.id, values, formikProps) && (
                        <div className="tableRowButton">
                            <Button
                                size={ButtonSize.Large}
                                theme={Theme.Positive}
                                onClick={(e: any) => {
                                    addEmptyRow(e, group.rows);
                                }}
                            >
                                {group.buttontext}
                            </Button>
                        </div>
                    )}
                </div>
            );
        }
        return rows;
    }

    function canExpandRows(otherTransactionsCounter: number, groupid: string, keyvalues: any, formikProps: any) {
        const currentRowTypeId: string = groupid + '_' + (otherTransactionsCounter - 1) + '_' + keyvalues[DynamicField.First].id;
        // Type is selected
        if (formikProps.values[currentRowTypeId] !== undefined && formikProps.values[currentRowTypeId].length > 0) {
            // ..and no error messages exists
            const currentRowId = groupid + '_' + (otherTransactionsCounter - 1) + '_' + keyvalues[DynamicField.Second].id;
            const currentRowPrincipalAmount = groupid + '_' + (otherTransactionsCounter - 1) + '_' + keyvalues[DynamicField.Third].id;
            if (formikProps.errors[currentRowId] === undefined && formikProps.errors[currentRowPrincipalAmount] === undefined) {
                return true;
            }
        }
        return false;
    }

    function rowsRemaining(rows: number) {
        if (rows !== undefined && otherTransactionsCounter < rows) {
            return true;
        }
        return false;
    }

    function addEmptyRow(e: any, rows: number | undefined) {
        if (rows !== undefined) {
            if (otherTransactionsCounter < rows) {
                setOtherTransactionsCounter(otherTransactionsCounter + 1);
            }
        }
        e.preventDefault();
        e.stopPropagation();
    }
    // Create fields end ------------------

    function confirm(values: FormikValues): Promise<boolean> {
        type Headers = { [key: string]: string };
        const defaultHeaders: Headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        };
        const config: AxiosRequestConfig = {
            headers: defaultHeaders,
        };
        return Axios.post('/api/Application/SubmitApplication/' + applicationContextValue.applicationId, config).then(
            (response: AxiosResponse<any>) => {
                if (response.status === 204) {
                    // Go to success page
                    updateApplicationValues(values, undefined, undefined, undefined, applicationContextValue);
                    setPageId(pageId + 1);
                    return true;
                } else {
                    messageContext.handleAPIError('Feil ved innsending av søknad', response);
                }
                return false;
            },
            (error: any) => {
                // todo: logging
                // bubbleErrorToContext(error.message, 'Feil ved innsending');
                messageContext.handleAPIError('Feil ved innsending av søknad', error.response);

                if (error.response.status === 401) {
                    console.error('Detected code 401 Unauthorized for post submitapplication, reautenticating...');
                    updateApplicationValues(values, undefined, undefined, undefined, applicationContextValue);
                    saveApplicationValuesIntoSession();
                    reAuthenticate();
                }
                return false;
            }
        );
    }

    function save(payload: any, values: FormikValues): Promise<boolean> {
        // console.log('payload');
        // console.log(payload);
        // console.log('values');
        // console.log(values);
        if (payload !== undefined && payload.metadata !== undefined && payload.metadata.groupId !== undefined) {
            const body = { GroupId: payload.metadata.groupId, Content: payload };
            type Headers = { [key: string]: string };
            const defaultHeaders: Headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            };
            const config: AxiosRequestConfig = {
                headers: defaultHeaders,
            };

            if (applicationContextValue.applicationId === undefined || applicationContextValue.applicationId === '') {
                return Axios.post('/api/Application', body, config)
                    .then(
                        (response: AxiosResponse<any>) => {
                            if (response.status >= 200 && response.status < 300) {
                                if (response.status === 200) {
                                    updateApplicationValues(values, undefined, undefined, undefined, applicationContextValue);
                                    const action: IDispatchAction = {
                                        Command: 'applicationId',
                                        FieldName: '',
                                        Value: response.data,
                                        Values: {},
                                    };
                                    applicationContextValue.dispatch(action);
                                    messageContext.addMessage({ message: 'Søknaden er lagret.', type: 'success', ttl: 2000 });
                                    return true;
                                }
                            } else {
                                messageContext.handleAPIError('Feil ved lagring av søknad', response);
                            }
                            return false;
                        },
                        (error: any) => {
                            messageContext.handleAPIError('Feil ved lagring av søknad.', error.response);

                            if (error.response.status === 401) {
                                console.error('Detected code 401 Unauthorized for post, reautenticating...');
                                updateApplicationValues(values, undefined, undefined, undefined, applicationContextValue);
                                saveApplicationValuesIntoSession();
                                reAuthenticate();
                            }

                            return false;
                        }
                    )
                    .finally(() => savePersonalFields(payload));
            } else {
                return Axios.put('/api/Application/' + applicationContextValue.applicationId, body, config)
                    .then(
                        (response) => {
                            if (response.status >= 200 && response.status < 300) {
                                updateApplicationValues(values, undefined, undefined, undefined, applicationContextValue);
                                messageContext.addMessage({ message: 'Søknaden er lagret.', type: 'success', ttl: 2000 });
                                return true;
                            } else {
                                messageContext.handleAPIError('Feil ved lagring av søknad', response);
                                console.error(JSON.stringify(response));
                            }
                            return false;
                        },
                        (error) => {
                            messageContext.handleAPIError('Feil ved lagring av søknad.', error.response);

                            if (error.response.status === 401) {
                                console.error('Detected code 401 Unauthorized for put, reautenticating...');

                                updateApplicationValues(values, undefined, undefined, undefined, applicationContextValue);
                                saveApplicationValuesIntoSession();
                                reAuthenticate();
                            }

                            return false;
                        }
                    )
                    .finally(() => savePersonalFields(payload));
            }
        } else {
            return new Promise(() => {
                console.error('Kunne ikke oppdatere. Sjekk om bruker har gruppe.');
                return false;
            });
        }
    }

    function savePersonalFields(payload: any): void {
        // Save personal editable info
        // AAD fields should not be updateable for the user
        if (pageId === 0) {
            const personalFieldsWithValue: any[] = [];
            const configuredFields = schema.pages[0].values.filter((v) => {
                return (v as IValue).stash === 'Personal';
            });

            configuredFields.forEach((f) => {
                if (!(f as IValue).readonly === true) {
                    personalFieldsWithValue.push(payload.fields.filter((p: any) => p.id === f.id)[0]);
                }
            });
            // console.log('Todo: Stash away personal info somewhere:');
            // console.log(JSON.stringify(personalFieldsWithValue));
        }
    }

    // todo: fix checkboxes
    function generateTouched(values: any) {
        let touched;
        if (values !== undefined) {
            touched = cloneDeep(values);
            for (const index in touched) {
                touched[index] = false;
            }
            return touched;
        }
        return undefined;
    }

    function clickedTestButton2(e: any) {
        messageContext.addMessage({ message: 'Test of success 2000.', tooltip: 'Test of tooltip.', type: MessageType.Success, ttl: 2000 });
        messageContext.addMessage({ message: 'Test of success 4000.', tooltip: 'Test of tooltip.', type: MessageType.Success, ttl: 4000 });
        messageContext.addMessage({ message: 'Test of error.', tooltip: 'Test of tooltip.', type: MessageType.Error });
        messageContext.addMessage({ message: 'Test of duplicate error.', tooltip: 'Test of tooltip.', type: MessageType.Error });
        messageContext.addMessage({ message: 'Test of duplicate error.', tooltip: 'Test of tooltip.', type: MessageType.Error });
        messageContext.addMessage({ message: 'Test of warning.', tooltip: 'Test of tooltip.', type: MessageType.Warning });
        messageContext.addMessage({ message: 'Test of information.', tooltip: 'Test of tooltip.', type: MessageType.Info });
        e.preventDefault();
        e.stopPropagation();
    }

    // If there is an error message for the current page, then return false,
    // regardless if the field is touched or not.
    function validatePage(formikProps: any) {
        // First find the id's for the current page
        const fieldIds: string[] = [];
        const page = schema.pages[pageId];

        page.values.forEach((value) => {
            if (isValue(value)) {
                fieldIds.push(value.id);
            } else if (isGroup(value)) {
                if (value.rows !== undefined) {
                    for (let index = 0; index < value.rows; index++) {
                        value.values.forEach((_value) => {
                            fieldIds.push((value as IGroup).id + '_' + index + '_' + _value.id);
                        });
                    }
                } else {
                    value.values.forEach((_value) => {
                        if (_value.type === 'group-checkbox') {
                            fieldIds.push(value.id + '_' + _value.id);
                        } else if (_value.standaloneid !== undefined && _value.standaloneid === true) {
                            fieldIds.push(_value.id);
                        } else {
                            fieldIds.push(value.id + '_' + _value.id);
                        }
                    });
                }
            }
        });

        // console.log('fieldIds for page: ' + pageId);
        // console.log(fieldIds);

        // console.log('errors');
        // console.log(formikProps.errors);

        // console.log('touched');
        // console.log(formikProps.touched);

        // Check if there is an error for these fields
        for (const fieldId of fieldIds) {
            if (formikProps.errors[fieldId] !== undefined) {
                return false;
            }
        }
        return true;
    }

    function pageWithAccountNumber() {
        return pageId === 2 || pageId === 6;
    }

    function isNavLink(button: IButton) {
        if (button.path === undefined) {
            return true;
        }
        return false;
    }

    return (
        <ApplicationContextWrapper>
            <UserConsumer>
                {(loggedOnUser) =>
                    loggedOnUser &&
                    loggedOnUser.user &&
                    loggedOnUser.user.groups &&
                    loggedOnUser.user.groups.some((g) => g.isApplicantGroup) && (
                        <>
                            <div className={cn('blocker', { blocked: blocked === true })}>
                                <PageLoader className="inblocker" />
                            </div>
                            <div className="formWrapper">
                                {devTest() && <button onClick={clickedTestButton2}>Test messages</button>}
                                <div className="formContent">
                                    <div className="form">
                                        <Formik
                                            key="formik"
                                            initialValues={createInitialValues(schema)}
                                            enableReinitialize={true}
                                            validationSchema={generateYupSchema(schema.pages[pageId].values)}
                                            validateOnBlur={schema.pages[pageId].validateOnBlur}
                                            onSubmit={(values:FormikValues, actions: FormikHelpers<any>) => {
                                                // (We got to wait for the post to finish)
                                                setTimeout(() => {
                                                    // A bug with validation in the latest release is due to touched not resetting after submit
                                                    // Untouch every field manually
                                                    const touched: FormikTouched<any> = generateTouched(values);
                                                    if (touched !== undefined) {
                                                        actions.setTouched(touched);
                                                    } else {
                                                        console.warn('Validation formik 2.0.3: Could not set all fields untouched');
                                                    }

                                                    updateUserMetadata(values);

                                                    if (values['action'] === 'back') {
                                                        applicationContextValue.applicationId = undefined;
                                                        applicationContextValue.autoSavedValues = {};
                                                        applicationContextValue.fileCount = {};
                                                        resetCollapsed();
                                                        setOtherTransactionsCounter(1);
                                                        const appValues = loadApplicationValuesFromSession();
                                                        if (appValues !== undefined) {
                                                            localStorage.removeItem('ApplicationValues');
                                                        }
                                                        savedSchemaValues = undefined;
                                                        actions.resetForm();
                                                        resetOrgNumber();
                                                        setPageId(0);
                                                        return new Promise(() => {
                                                            actions.setSubmitting(false);
                                                            return true;
                                                        });
                                                    } else if (values['action'] === 'next') {
                                                        const payload = createPayLoad(schema, values, userContext);
                                                        return save(payload, values).then((success: boolean) => {
                                                            if (summaryPageActive.active === true) {
                                                                setPageId(summaryPageActive.summaryPageId);
                                                                setSummaryPageActive({ active: false, summaryPageId: 0 });
                                                            } else {
                                                                setPageId(pageId + 1);
                                                            }
                                                            window.scrollTo(0, 0);
                                                            actions.setSubmitting(false);
                                                            // Set the correct color for the 'next' button when the page is newly paged
                                                            actions.validateForm();
                                                            return success;
                                                        });
                                                    } else if (values['action'] === 'confirm') {
                                                        const payload = createPayLoad(schema, values, userContext);
                                                        setPageBlocked(true);
                                                        return save(payload, values).then((success: boolean) => {
                                                            if (success) {
                                                                return confirm(values).then((confirmSuccess) => {
                                                                    applicationContextValue.applicationId = undefined;
                                                                    applicationContextValue.fileCount = {};
                                                                    applicationContextValue.autoSavedValues = {};
                                                                    resetCollapsed();
                                                                    setOtherTransactionsCounter(1);
                                                                    actions.setSubmitting(false);
                                                                    const appValues = loadApplicationValuesFromSession();
                                                                    if (appValues !== undefined) {
                                                                        localStorage.removeItem('ApplicationValues');
                                                                    }
                                                                    savedSchemaValues = undefined;
                                                                    resetOrgNumber();
                                                                    actions.resetForm();
                                                                    setPageBlocked(false);
                                                                    return confirmSuccess;
                                                                });
                                                            }
                                                            actions.setSubmitting(false);
                                                            return new Promise(() => success);
                                                        });
                                                    } else if (values['action'] === 'previous') {
                                                        const payload = createPayLoad(schema, values, userContext);
                                                        return save(payload, values).then((success) => {
                                                            setPageId(pageId - 1);
                                                            window.scrollTo(0, 0);
                                                            actions.setSubmitting(false);
                                                            return success;
                                                        });
                                                    } else if (values['action'] === 'save') {
                                                        const payload = createPayLoad(schema, values, userContext);
                                                        return save(payload, values).then((success) => {
                                                            actions.setSubmitting(false);
                                                            return success;
                                                        });
                                                    } else {
                                                        return new Promise(() => {
                                                            actions.setSubmitting(false);
                                                            return true;
                                                        });
                                                    }
                                                }, 500);
                                            }}
                                        >
                                            {(props) => (
                                                <div className="layoutGridMain">
                                                    <div>
                                                        <div>
                                                            <FormPage stepCount={schema.pages.length} currentStep={pageId}>
                                                                <Form onSubmit={props.handleSubmit} autoComplete="off">
                                                                    <div className="formPageInner">
                                                                        <ShowMessages key="top" top={true} />
                                                                        {<div tabIndex={0} id="focus" ref={customfocus}></div>}
                                                                        {createFieldsCl(schema.pages[pageId].values, schema.pages[pageId].layout, props)}
                                                                        <div className={cn('buttons', { single: schema.pages[pageId].buttons.length === 1 })}>
                                                                            {schema.pages[pageId].buttons.map((button, i) => {
                                                                                let theme = Theme.Neutral;
                                                                                let variant: string | undefined = undefined;
                                                                                let formValidated = true;
                                                                                let icon = false;
                                                                                if (button.type === 'next' || button.type === 'confirm' || button.type === 'submit') {
                                                                                    formValidated = validatePage(props);
                                                                                    if (formValidated === true && props.isSubmitting === false && isUploadingFiles === false) {
                                                                                        theme = Theme.Positive;
                                                                                        if (button.type === 'confirm' && width > ResponsiveWidthThresholdMedium) {
                                                                                            icon = true;
                                                                                        }
                                                                                    } else {
                                                                                        theme = Theme.Neutral;
                                                                                    }
                                                                                } else if (button.type === 'save') {
                                                                                    theme = Theme.Neutral;
                                                                                    variant = 'outlined';
                                                                                } else if (button.color === 'green') {
                                                                                    theme = Theme.Positive;
                                                                                }

                                                                                return (
                                                                                    <div
                                                                                        className={cn(
                                                                                            'button-wrapper',
                                                                                            { 'button-spacer': i === 1 },
                                                                                            { last: i === schema.pages[pageId].buttons.length - 1 },
                                                                                            { single: schema.pages[pageId].buttons.length === 1 },
                                                                                            { left: schema.pages[pageId].buttons.length === 1 && button.position === 'left' }
                                                                                        )}
                                                                                        key={'button' + i}
                                                                                    >
                                                                                        {isNavLink(button) === true ? (
                                                                                            <Button
                                                                                                type="button"
                                                                                                size={width < ResponsiveWidthThresholdMedium ? ButtonSize.Medium : ButtonSize.Large}
                                                                                                disabled={props.isSubmitting || isUploadingFiles}
                                                                                                onClick={(e) => {
                                                                                                    setShowValidationErrors(true);
                                                                                                    if (button.type === 'previous') {
                                                                                                        setPageId(pageId - 1);
                                                                                                    } else {
                                                                                                        props.setFieldValue('action', button.type);
                                                                                                        props.handleSubmit();
                                                                                                    }
                                                                                                    e.preventDefault();
                                                                                                }}
                                                                                                theme={theme}
                                                                                                iconPosition={icon === true && IconPosition.Right}
                                                                                                iconName={icon === true && 'fal fa-arrow-right'}
                                                                                                variant={variant}
                                                                                            >
                                                                                                {summaryPageActive.active === true && button.type === 'next' ? 'Gå tilbake' : button.text}
                                                                                            </Button>
                                                                                        ) : (
                                                                                            <>
                                                                                                <NavLink className="navLinkButton" to={button.path || ''}>
                                                                                                    <Button type="button" disabled={props.isSubmitting || isUploadingFiles} theme={theme} size={ButtonSize.Large}>
                                                                                                        {button.text}
                                                                                                    </Button>
                                                                                                </NavLink>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                        <ShowMessages key="bottom" />
                                                                        <ContextTap />
                                                                    </div>
                                                                </Form>
                                                            </FormPage>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="infoArea">
                                                            <div className="infoBoxWrapper">
                                                                <div className="infoBoxRegular">
                                                                    <InfoBox title="Viktig informasjon">
                                                                        <p>Det er viktig at du kontrollerer at informasjonen er korrekt før innsending.</p>
                                                                        <p>Rettelser etter at innmeldingen er sendt kan kun gjøres ved å fylle ut et nytt søknadsskjema.</p>
                                                                    </InfoBox>
                                                                </div>
                                                            </div>
                                                            {pageWithAccountNumber() && (
                                                                <div className="infoBoxWrapper">
                                                                    <InfoBox title="NB!" arrow="Left">
                                                                        <p>Vi opplever at en del søknader blir innsendt med feil lånenummer. Vennligst dobbeltsjekk dette.</p>
                                                                    </InfoBox>
                                                                </div>
                                                            )}
                                                            <div className="infoBoxWrapper">
                                                                <div className="infoBoxRegular">
                                                                    <Constraint loginTimeStamp={userContext.loginTimeStamp} width={width} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </UserConsumer>
        </ApplicationContextWrapper>
    );

    function updateUserMetadata(formikValues: any) {
        const groupValues = schema.pages[0].values.filter(isGroup) as IGroup[];
        const schemaValues: IValue[] = [];
        groupValues.forEach((g) => {
            g.values.forEach((v) => {
                schemaValues.push(v);
            });
        });
        // .filter((v) => v.type === 'text' || v.type === 'phonenumber' || v.type === 'region');

        if (schemaValues.some((v) => formikValues[v.id] !== v.value) && pageId === 0) {
            const mappedValues = schemaValues.map((v) => ({ id: v.id, value: formikValues[v.id] || v.value }));
            const v = mapValues(keyBy(mappedValues, 'id'), 'value');
            const userMetadata = {
                metaData: v,
            };
            Axios.put('/api/session/user', JSON.stringify(userMetadata), {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(
                (r) => {
                    r.status === 204
                        ? // Todo: error handling. Though this is a non critical nice to have feature, so
                          // silent failure and continue is acceptable.
                          console.log('Saved user metadata')
                        : console.warn('Saving user metadata failed');
                },
                (error) => {
                    messageContext.addMessage({ message: 'Feil ved updatering av brukedata.', type: 'error' });
                    console.error(error);
                }
            );
        }
    }
}

export default InputSchema;
